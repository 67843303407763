import "./App.css";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        Coming soon...
      </header>
    </div>
  );
}

export default Home;
