import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Main";
import Home from "./Home";
import Orders from "./Orders";
import OrderEmails from "./OrderEmails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/x/" element={<Main />} />
        <Route path="/x/o" element={<Orders />} />
        <Route path="/x/oe" element={<OrderEmails />} />
      </Routes>
    </Router>
  );
}

export default App;
