
import { useState, useEffect } from 'react';
import axios from 'axios';
import { TableContainer, TableBody, TableHead, TableRow, TableCell, Table } from '@mui/material';

const serverUrl = 'https://api.huely.io'

const Orders = () => {

  const [results, setResults] = useState<any[]>([]);

  useEffect(() => {
    axios.get(`${serverUrl}/v1/accounts/0eea7dca-4536-4cfc-a14c-d90ee23e7cc8/orders`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_key')}`,
      }
    }).then((res) => {
      console.log(res.data)
      setResults(res.data.results)
    });
  }, [])

  return (<div>
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Order ID
            </TableCell>
            <TableCell>
              Email
            </TableCell>
            <TableCell>
              Items
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results && results.map((row) => (
            <TableRow>
              <TableCell>
                {row.commerce_order_id}
              </TableCell>
              <TableCell>
                {row.buyer_email}
              </TableCell>
              <TableCell>
                {row.items && row.items.map((i: any) => (
                  <TableRow>
                    <TableCell>
                      {i.display_name}
                    </TableCell>
                    <TableCell>
                      {i.quantity}
                    </TableCell>
                    {i.custom && Object.entries(i.custom).map(([k, v]) => (
                      <TableRow>
                        <TableCell>
                          {k}
                        </TableCell>
                        <TableCell>
                          {i.custom[k]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableRow>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>



  </div>);
};
export default Orders
